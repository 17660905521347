import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Token } from '@token';
import { Observable, of } from 'rxjs';
import { AuthAction } from '../store/auth/auth.actions';
import { LOGIN_ROUTE } from '../public/constants/route.constant';
import { HomeAction } from '../store/home/home.actions';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private store: Store, private _token: Token, private _router: Router) {}

  canActivate(): Observable<boolean> {
    if(this._token.hasValue && this._token.conferenceId) {
        this.store.dispatch(new AuthAction.AuthGuardHandler())
        if(localStorage.getItem('conference')) {
          this.store.dispatch(new HomeAction.GetHomeEventData(localStorage.getItem('conference') || ''))
        }
        return of(true)
    } else {
      if(!this._token.conferenceId) {
        this._router.navigate(['not-found'])
      } else {
        this._router.navigate([LOGIN_ROUTE.url])
      }
        return of(false)
    }
  }

  canLoad(): Observable<boolean> {
    if(this._token.hasValue && this._token.conferenceId) {
        this.store.dispatch(new AuthAction.AuthGuardHandler())
        return of(true)
    } else {
      if(!this._token.conferenceId) {
        this._router.navigate(['not-found'])
      } else {
        this._router.navigate([LOGIN_ROUTE.url])
      }
        return of(false)
    }
  }
}
