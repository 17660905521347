import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Token } from '@token';
import { Observable, tap } from 'rxjs';
import { LoaderService } from '../loader';
import { PopupService } from '../popup';
import { MESSAGES } from 'src/app/constants/messages.constants';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LOGIN_ROUTE } from 'src/app/public/constants/route.constant';
import { AuthAction } from 'src/app/store/auth/auth.actions';

@Injectable()
export class InterceptorService {

  constructor(private _token: Token, private _loader: LoaderService, private _popup: PopupService, private _router: Router, private _store: Store) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!navigator.onLine) {
      // this.$popup.error(MESSAGES.OFFLINE, { duration: 4000 });
      // return throwError(new Error('oops!'));
    }
    const isApiUrl = req.url.charAt(0) === '~' || req.url.charAt(0) === '^';
    const authorization = this._token.hasValue && isApiUrl ? this._token.header : '';
   
      const headers = {
        authorization: req.headers.get('Authorization') || authorization,
    }
    return next.handle(!(isApiUrl) ? req : req.clone(req.url.charAt(0) === '~' ? {
      setHeaders: headers,
      url: isApiUrl ? `${environment.apiBaseUrl}${req.url.substr(1)}` : req.url // add base url
    } : {
      url: isApiUrl ? `${environment.apiBaseUrl}${req.url.substr(1)}` : req.url // add base url
    })).pipe(
      tap(event => {
        this._loader.completeLoading();
        if (event instanceof HttpResponse) {
          // console.log('response');
        }
        // return throwError(error);
      }, event => {
        if (event instanceof HttpErrorResponse) {
          this._loader.completeLoading();
          console.log(event)
          if ((event.status === 401 || event.status === 423 || event.status === 410 || event.status === 419) && this._token.hasValue) {
            this._token.remove()
            this._token.value = ''
            this._store.dispatch(new AuthAction.ResetProfile())
            this._router.navigate([LOGIN_ROUTE.url])
            this._popup.error(MESSAGES.ERROR.$401);
          }
          else  if ((event.status === 401 || event.status === 401 || event.status === 423 || event.status === 410 || event.status === 419) && !this._token.hasValue) {
            this._token.remove()
            this._token.value = ''
            this._store.dispatch(new AuthAction.ResetProfile())
            this._router.navigateByUrl(LOGIN_ROUTE.url);
            if(event.error.message) {
              this._popup.error(event.error.message);
            } else {
              this._popup.error(MESSAGES.ERROR.$401);
            }
          } else if (event.status === 504) {
            // this.$popup.error(MESSAGES.ERROR.$504);
          }
           else if (event.status === 410) {
          //   this.$profile.clear();
          //   this.$router.navigateByUrl(PUBLIC_ROUTE.url);
            this._popup.error(MESSAGES.ERROR.$410)
          }
          else if (event.status === 503) {
            this._popup.error(MESSAGES.ERROR.$503)
          } else {
              this._popup.error(event.error.message || MESSAGES.ERROR.UNKNOWN);
          }
        }
      })
    );
  }
  
}
