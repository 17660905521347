import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HOME, LAYOUT_ROUTE, PUBLIC_ROUTE } from './constants/route.constants';
import { PublicGuard } from './guards/public.guard';
import { AuthGuard } from './guards/auth.guard';
import { DASHBOARD_ROUTE, REGISTRATION_ROUTE } from './layout/constants/routes.constants';
import { LOGIN_ROUTE } from './public/constants/route.constant';

const routes: Routes = [
  { 
    path: HOME.path, 
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule) 
  }, 
  { 
    path: LOGIN_ROUTE.path, 
    loadChildren: () => import('./public/pages/login/login.module').then(m => m.LoginModule),
    canActivate: [PublicGuard],
    canLoad: [PublicGuard]
  }, 
  { 
    path: DASHBOARD_ROUTE.path, 
    loadChildren: () => import('./layout/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard] 
  },
  {
    path: `${REGISTRATION_ROUTE.path}/:conferenceId`,
    loadChildren: () => import('./layout/pages/registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  {
    path: '**',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
