export const LAYOUT_ROUTE = {
    path: '',
    get url(): string {
        return `/${this.path}`
    } 
}

export const PUBLIC_ROUTE = {
    path: '',
    get url(): string {
        return `/${this.path}`;
    }
};

export const HOME = {
    path: '',
    get url(): string {
        return `/${this.path}`;
    }
};