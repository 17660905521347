import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store'
import { HomeState } from 'src/app/store/home/home.state';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  emailId: string = ''

  constructor(private _store: Store) {}

  ngOnInit(): void {

    this._store.select(HomeState.conferenceData).subscribe((res:any) => {
      if(res) {
        this.emailId = res[localStorage.getItem('conference') || '']?.header.email
      }
    })
    
  }

}
