import { NgModule } from "@angular/core";
import { HeaderComponent } from "./header.component";
import { CommonModule } from "@angular/common";
import { PopupModule } from "@progress/kendo-angular-popup";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";

@NgModule({
    declarations: [
        HeaderComponent,
      ],
      imports: [
        CommonModule,
        PopupModule,
        IndicatorsModule
      ], 
      exports: [HeaderComponent]
    })
 export class HeaderModule { }