import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Token } from '@token';
import { Observable, of } from 'rxjs';
import { DASHBOARD_ROUTE } from '../layout/constants/routes.constants';
import { HomeAction } from '../store/home/home.actions';


@Injectable({
  providedIn: 'root'
})
export class PublicGuard implements CanActivate, CanLoad {
  constructor(private store: Store, private _token: Token, private _router: Router) {}

  canActivate(): Observable<boolean> {
    if(!this._token.hasValue && this._token.conferenceId) {
      if(localStorage.getItem('conference')) {
        this.store.dispatch(new HomeAction.GetHomeEventData(localStorage.getItem('conference') || ''))
      }
        return of(true)
    } else {
      if(this._token.conferenceId) {
        this._router.navigate([DASHBOARD_ROUTE.url])
      } else {
        this._router.navigate(['not-found'])
      }
        
        return of(false)
    }
  }

  canLoad(): Observable<boolean> {
    if(!this._token.hasValue && this._token.conferenceId) {
      return of(true)
  } else {
    if(this._token.conferenceId) {
      this._router.navigate([DASHBOARD_ROUTE.url])
    } else {
      this._router.navigate(['not-found'])
    }
      
      return of(false)
  }
  }
}