import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store'
import { Token } from '@token';
import { HOME } from 'src/app/constants/route.constants';
import { DASHBOARD_ROUTE } from 'src/app/layout/constants/routes.constants';
import { LOGIN_ROUTE } from 'src/app/public/constants/route.constant';
import { AuthAction } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';
import { HomeState } from 'src/app/store/home/home.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  heading!: string
  profileData: any
  public toggleText = "Hide";
  public show = false;
  isLoginPage:boolean = true;
  showHomeIcon: boolean = false
  logoUrl!: string
 
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const popup = document.querySelector('.profile-popup');
    
    if (this.show && popup && !popup.contains(target)) {
      this.show = false;
    }
  }

  isDataLoading: boolean = true

  constructor(private _store: Store, public _token: Token, private _router: Router, public _route:ActivatedRoute) { }

  ngOnInit(): void {
    this._store.select(HomeState.isDataLoading).subscribe(loading => {
           this.isDataLoading = loading
    })
    this._store.select(AuthState.heading).subscribe(heading => {
      this.heading = heading
    })
    this._store.select(AuthState.profileData).subscribe(data => {
      this.profileData = data;
    })
    this._store.select(AuthState.isLoginPage).subscribe(data => {
      this.isLoginPage = data
    })
    this._store.select(AuthState.showHomeIcon).subscribe(data => {
      this.showHomeIcon = data
    })
    this._store.select(HomeState.conferenceData).subscribe(data => {
       this.logoUrl = data[localStorage.getItem('conference') || this._route.snapshot.queryParams['conference']]?.header?.logoUrl
    })
  }

logout() {
  this._token.remove()
  this.show = false;
  this._token.value = ''
  this._store.dispatch(new AuthAction.ResetProfile())
  this._router.navigate([HOME.url], {queryParams: {conference: this._token.conferenceId}})
}

 onToggle(): void {
    this.show = !this.show;
    this.toggleText = this.show ? "Hidе" : "Show";
  }

  login() {
    this.isDataLoading = false
    if(this._token.hasValue && this._token.conferenceId) {
      this._router.navigate([DASHBOARD_ROUTE.url])
    } else {
      this._router.navigate([LOGIN_ROUTE.url])
    }
  }

  gotoHome() {
    this._router.navigate([DASHBOARD_ROUTE.url])
  }

}
