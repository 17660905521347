
    <div class="footer-container container">
      <div class="align-items-center justify-content-between row text-white">
        <div class="col-auto py-2">
          <img src="../../../assets/img/logo.svg" alt="" width="100">
        </div>
  
        <p class="col-auto  py-2">
          Contact Information: <br>
          {{emailId}}
        </p>
      </div>
</div>