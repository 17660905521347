import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterceptorModule } from './services/interceptor/interceptor.module';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule, NgxsStoragePluginOptions } from '@ngxs/storage-plugin';
import { DashboardState } from './store/dashboard/dashboard.state';
import { DashboardService } from './layout/pages/dashboard/services/dashboard.service';
import { RegistrationState } from './store/registration/registration.state';
import { RegistrationService } from './layout/pages/registration/services/registration.service';
import { AuthState } from './store/auth/auth.state';
import { HeaderModule } from './common/header/header.module';
import { FooterModule } from './common/footer/footer.module';
import { HomeState } from './store/home/home.state';


const storageOptions: NgxsStoragePluginOptions = {
  keys: ['key']
};


@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    // FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InterceptorModule,
    IndicatorsModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([DashboardState, RegistrationState, AuthState, HomeState], { developmentMode: /** !environment.production */ true }),
    NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(storageOptions),
    HeaderModule,
    FooterModule
  ],
  providers: [DashboardService, RegistrationService],
  bootstrap: [AppComponent],
  // exports: [HeaderComponent, FooterComponent]
})
export class AppModule { }
